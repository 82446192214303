<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-start align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <router-link :to="{ name: 'mypage-index' }">
            <img :src="require('@/assets/logo.svg')" width="180" height="88" />
          </router-link>
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-0">
        <v-col cols="12" class="text-center">
          <h3 :class="$style.title">{{ titleEn }}</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex justify-center align-center"
          v-if="Object.keys(errorMessages).length"
        >
          <v-card
            flat
            width="300"
            background="transparent"
            :style="{
              backgroundColor: 'transparent'
            }"
          >
            <error-list :errorMessages="errorMessages" :names="['message']" />
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            color="#fff"
            class="py-1 pa-5 mb-3 rounded-xl"
            rounded
            :class="$style.form"
          >
            <v-row dense>
              <v-col cols="12">
                <MyForm
                  :formKey="formKey"
                  :entity="entity"
                  :input="input"
                  ref="form"
                >
                  <v-container class="pa-0">
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: 'required'
                          }"
                          name="お名前"
                          vid="name"
                          ref="v-name"
                        >
                          <v-text-field
                            v-model="input['name']"
                            name="name"
                            label="お名前 User Name"
                            clearable
                            placeholder="お名前を入力"
                            autocomplete="off"
                            :error-messages="errors[0]"
                            background-color="#fff"
                            disabled
                            :class="$style['inquiry-input']"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: 'required',
                            email: 'email'
                          }"
                          name="メールアドレス"
                          vid="mailAddress"
                          ref="v-mailAddress"
                        >
                          <v-text-field
                            v-model="input['mailAddress']"
                            name="mailAddress"
                            label="メールアドレス Email Address"
                            clearable
                            placeholder="jamid@sample.com"
                            autocomplete="off"
                            :error-messages="errors[0]"
                            background-color="#fff"
                            disabled
                            :class="$style['inquiry-input']"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: 'required'
                          }"
                          name="お問い合わせ内容"
                          vid="contents"
                          ref="v-contents"
                        >
                          <v-textarea
                            v-model="input['contents']"
                            name="contents"
                            label="お問い合わせ内容 Details"
                            clearable
                            autocomplete="off"
                            :error-messages="errors[0]"
                            background-color="#fff"
                            :rows="4"
                            auto-grow
                            :class="$style['inquiry-input']"
                          >
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-container>
                </MyForm>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="7">
                <base-btn
                  :disabled="btnDisabled"
                  label="送信する Submit"
                  @click="onConfirm"
                  :style="{
                    color: 'white',
                    fontSize: '1rem',
                    boxShadow: 'none'
                  }"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="text-center"
            text
            color="#5993ac"
            @click="goBack"
            :class="$style['back-to-top-btn']"
          >
            <v-icon :class="$style['back-to-top-icon']"
              >mdi-chevron-left</v-icon
            >
            戻る (Back)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import utility from "@/utility";
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";

export default {
  components: {
    MyForm,
    ErrorList
  },
  data() {
    return {
      resourceName: "inquiry",
      formKey: "inquiry-index",
      title: "お問い合わせ",
      titleEn: "Inquiry",
      input: {
        name: "",
        mailAddress: "",
        contents: ""
      },
      valid: {
        name: false,
        mailAddress: false,
        contents: false
      }
    };
  },
  watch: {
    "input.contents": {
      handler(value, old) {
        if (value !== old) {
          this.validate("contents");
        }
      }
    }
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/isProcessing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    },
    accountInfo() {
      return this.$store.getters[`accounts/entity/accountInfo`] || {};
    },
    btnDisabled() {
      const { contents } = this.valid;
      return !this.input["name"] || !this.input["mailAddress"] || !contents;
    },
    userName() {
      if (this.accountInfo.firstName && this.accountInfo.lastName) {
        return (
          `${this.accountInfo.firstName} ${this.accountInfo.lastName}` || ""
        );
      }
      return "";
    }
  },
  methods: {
    async validate(refName) {
      const providor = this.$refs[`v-${refName}`];
      const { valid } = await providor.validate();
      this.$nextTick(() => {
        this.valid[refName] = valid;
      });
    },
    onConfirm() {
      this.$store
        .dispatch(`${this.resourceName}/entity/inquiry`, {
          name: this.input["name"],
          mailAddress: this.input["mailAddress"],
          contents: this.input["contents"],
          accountId: this.accountInfo["accountId"]
        })
        .then(() => {
          this.$store.commit("form/clearDirty");
          this.$router.push({ name: "mypage-index" });
        })
        .catch(() => {});
    },
    goBack() {
      this.$router.push({ name: "mypage-index" });
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`${this.resourceName}/entity/initialize`);
    this.$store.commit(`accounts/entity/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();

    this.$store.dispatch(`accounts/entity/getAccountInfo`).then(() => {
      this.input = {
        name: this.userName,
        mailAddress: this.accountInfo["mailAddress"],
        contents: this.input["contents"]
      };
    });
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.separator {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.back-to-top-btn {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.back-to-top-icon {
  margin-top: 2px;
}

.inquiry-input {
  label {
    font-size: 15px !important;
  }
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}

.v-list {
  .v-list-item {
    .v-list-item__title {
      color: #718490;
      font-size: 1.3rem;
    }

    .v-list-item__subtitle {
      font-size: 1.2rem;
      color: #718490;
    }

    .v-list-item__icon {
      .v-icon {
        color: #718490;
      }
    }
  }
}
</style>
